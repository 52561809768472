(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/account-balance/assets/javascripts/account-balance.js') >= 0) return;  svs.modules.push('/components/components/account-balance/assets/javascripts/account-balance.js');

'use strict';

svs.components = svs.components || {};
svs.components.account_balance = svs.components.account_balance || {};
let accountBalance = null;
let lastUpdated = null;
let inProgress = false;
const BALANCE_CACHE_TIME = 3 * 60 * 1000; 
const log = new svs.core.log.Logger('svs.components.account_balance');
if (svs.components.account_balance.data && svs.components.account_balance.data.ss_account_balance) {
  log.debug('Retrieved serverside account balance data, using to seed cache');
  accountBalance = svs.components.account_balance.data.ss_account_balance.balance;
  lastUpdated = Date.now();
  delete svs.components.account_balance.data.ss_account_balance;
}

const get = function (forceRetrieveBalanceFromJupiter, callback, context) {
  const playerInfoApiVersion = svs.core.detect.tenant('tb') ? 2 : 1;
  if (typeof forceRetrieveBalanceFromJupiter === 'function') {
    callback = forceRetrieveBalanceFromJupiter;
    context = callback;
    forceRetrieveBalanceFromJupiter = false;
  }
  log.debug('Getting account balance');
  if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    if (!forceRetrieveBalanceFromJupiter && accountBalance && lastUpdated && Date.now() - lastUpdated < BALANCE_CACHE_TIME) {
      if (callback) {
        log.debug('Get cached balance');
        callback.call(context, accountBalance);
      }
    } else if (inProgress) {
      log.debug('Request in progress, waiting for trigger to get balance');
      inProgress = true;

      const listener = accountBalance => {
        log.debug('Account balance retrieved from first request. Now change event is triggered to get balance');
        if (callback) {
          callback.call(context, accountBalance);
        }
        svs.components.account_balance.off('change', listener);
      };
      svs.components.account_balance.on('change', listener);
    } else {
      try {
        inProgress = true;
        log.debug('Get balance from jupiter');
        svs.core.jupiter.call({
          method: 'GET',
          path: "/player/".concat(playerInfoApiVersion, "/info?include=accountBalance")
        }, response => {
          log.debug('Account balance retrieved.');
          const retrievedAccountBalance = response.accountBalance;
          set(retrievedAccountBalance);
          inProgress = false;
          if (callback) {
            callback.call(context, retrievedAccountBalance);
          }
        }, (jqXHR, textStatus, err) => {
          if (jqXHR.status === 0) {
            if (callback) {
              callback.call(context, accountBalance, err);
            }
            return;
          }
          let error = 'Failed to retrieve account balance.';
          if (err) {
            error += " Error: ".concat(err);
          }
          log.warn(error);
          set(accountBalance);
          if (callback) {
            callback.call(context, accountBalance, err);
          }
        });
      } catch (ex) {
        log.warn("Generic error: Failed to retrieve account balance, error: ".concat(ex.message, ".").concat(ex.stack));
        set(accountBalance);
        if (callback) {
          callback.call(context, accountBalance, ex);
        }
      }
    }
  } else if (callback) {
    callback.call(context, null);
  }
};

const set = function (newAccountBalance) {
  lastUpdated = Date.now();
  if (newAccountBalance !== accountBalance || inProgress) {
    inProgress = false;
    accountBalance = newAccountBalance;
    svs.components.account_balance.trigger('change', accountBalance);
  }
};
const reset = function () {
  lastUpdated = accountBalance = undefined;
};
svs.components.account_balance = new svs.core.Events({
  get,
  set,
  reset
});

 })(window);